import { get, post } from "@/utils/axios.js";

export function fetchPickableLbItems(userGuid, groupGuid) {
  return get("/grouplb/PickableGroupLbList", {
    t: new Date().getTime(),
    customerGuid: userGuid,
    groupGuid
  });
}

export function fetchPickedLbItems(groupGuid) {
  return get("/grouplb/PickedGroupLbList", {
    t: new Date().getTime(),
    groupGuid
  });
}

export async function addLbsToGroup(groupGuid, lbGuidList) {
  await post(`/grouplb/AddLbsToGroup/${groupGuid}`, lbGuidList);
}

export async function removeLbFromGroup(groupGuid, lbGuid) {
  await post(`/grouplb/RemoveLbFromGroup/${groupGuid}`, JSON.stringify(lbGuid));
}

export async function updateGroupLbOrder(groupGuid, lbIdList) {
  await post(`/grouplb/UpdateGroupLbOrder/${groupGuid}`, lbIdList);
}

export function getLbListWithCategory(userGuid) {
  return get(`/lbmanage/LbInfoWithCategory/${userGuid}`);
}

export function getLbDetails(lbGuid) {
  return get(`/lbmanage/LbDetails/${lbGuid}`);
}

export function getLbCategoryList(userGuid) {
  return get(`/lbmanage/AllLbCategoryList/${userGuid}`);
}

export function getLbAlerts(lbGuid) {
  return get(`/lbmanage/LbAlerts/${lbGuid}`);
}

export function getLbScoreFieldNames(lbGuid) {
  return get(`/lbmanage/LbScoreFieldNames/${lbGuid}`);
}

export function updateLbDetails({
  lbGuid,
  dispName,
  timeBoxSecond,
  isTopMost,
  isQuesShuffle
}) {
  return post("/lbmanage/UpdateLbDetails", {
    lbGuid,
    dispName,
    timeBoxSecond,
    isTopMost,
    isQuesShuffle
  });
}

export async function updateLbAlertDisp({
  alertDispGuid,
  displayName,
  colorHex
}) {
  let response = await post("/lbmanage/UpdateLbAlertDisplay", {
    guid: alertDispGuid,
    displayName,
    colorHex
  });
  return response.guid;
}

/**
 * @param {*} conditionList
 * {
 *    alertDispGuid: String,
 *    scoreFieldName: String,
 *    scoreOperator: String,
 *    scoreValue: Number,
 *    logicOrder: Number,
 *    logicOperator: String
 * }
 * @returns
 */
export async function updateLbAlertConditions(conditionList) {
  let response = await post("/lbmanage/UpdateLbAlertConditions", conditionList);
  return response.value;
}

export async function removeLbAlertDisp({ guid }) {
  let response = await post("/lbmanage/RemoveOneLbAlertDisplay", {
    guid
  });
  return response.value;
}

export async function removeLbAlertConditions(conditionGuidList) {
  let response = await post(
    "/lbmanage/RemoveLbAlertConditions",
    conditionGuidList
  );
  return response.value;
}
